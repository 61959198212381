<li
    bind:this={cardElement}
    id="itsl-plan-card-{plan.id}"
    class="itsl-plan-card {hiddenClass} {hoverSupportClass}"
    role={keyboardReorder ? "option" : undefined}
    class:itsl-plan-card--shadow={dragAndDropSelected}
    class:itsl-plan-card--drag={keyboardReorderSelected}
    aria-selected={keyboardReorder ? keyboardReorderSelected : undefined}
    on:click={measurePlanOpensFromCalendar}
>
    {#if isPlanClickable}
        <a
            tabindex={disabled ? -1 : 0}
            href={destinationUrl}
            class="itsl-plan-card__link"
            aria-labelledby="{id}--title"
            {target}
            rel="noopener"
            on:click
        >
            <span class="screen-reader">{titleText}</span>
        </a>
    {/if}
    {#if showSelect || showDrag}
        <div class="itsl-plan-card__controls">
            {#if showSelect}
                <Checkbox
                    bind:checked
                    label="{i18n.select} {titleText}"
                    labelHidden
                    small
                    describedBy={null}
                    on:change={updateCheckedStatus(plan.id, checked)}
                />
            {/if}
            {#if showDrag}
                <button
                    bind:this={dragButton}
                    id="{id}--drag"
                    class="itsl-plan-card__drag"
                    on:mousedown={mouseDownDragButton}
                    on:touchstart={mouseDownDragButton}
                    on:click={clickDragButton}
                    on:keyup={keyUpDragButton}
                    on:blur={blurDragButton}
                    aria-labelledby="{id}--title"
                    aria-pressed={keyboardReorderSelected}
                ></button>
                <div
                    class="itsl-plan-card__tooltip-container"
                    aria-live="polite"
                    class:itsl-plan-card__tooltip-container--visible={tooltipVisible}
                >
                    {#if tooltipVisible}
                        <Tooltip
                            element={dragButton}
                            id="{id}-drag-tip"
                            placement="bottom"
                            role="tooltip"
                            show={tooltipVisible}
                            text={i18n.elementDragHandleTooltip}
                        />
                    {/if}
                </div>
            {/if}
        </div>
    {/if}

    <div class="itsl-plan-card__image" style="{topicColorStyle} {imageStyle}">
        {#if !canEdit && userCanStartPlan}
            <ProgressPlanLink
                {backExtraParams}
                columnVisibilities={columnVisibilities}
                containerClass="itsl-plan-card__image-button"
                courseId={config.courseId}
                iconClass="itsl-plan-card__image-button-icon"
                isPlanCard={true}
                kind="standalone"
                {plan}
                startAtPlanPage={true}
                useNextPlanFeature={false}
                useGenericResourceColumn={config.useGenericResourceColumn || useGenericResourceColumn}
            />
        {/if}

        {#if !plan.imageUrl && !plan.placeholderImageUrl}
            <div class="itsl-plan-card__image-icon"></div>
        {/if}
    </div>

    <div class="itsl-plan-card__info">
        <div class="itsl-plan-card__header">
            <div class="itsl-plan-card__title" id="{id}--title">
                <span>{titleText}</span>
                <div class="itsl-plan-card__hidden-indicator">
                    <span class="screen-reader">{i18n ? i18n.hidden : undefined}</span>
                </div>
            </div>
            {#if plan.topic}
                {#if hasAccessToPlan && showTopicAsLink}
                    <a
                        tabindex={disabled ? -1 : 0}
                        href={plan.topic.url}
                        style={topicColorStyle}
                        class="itsl-plan-card__topic"
                        {target}
                        rel="noopener"
                    >
                        <span>{plan.topic.name}</span>
                        <span class="screen-reader">: {topicName}</span>
                    </a>
                {:else}
                    <span style={topicColorStyle} class="itsl-plan-card__topic">
                        <span>{plan.topic.name}</span>
                        <span class="screen-reader">: {topicName}</span>
                    </span>
                {/if}
            {/if}
            {#if isCourseOverviewPage && plan.pinned}
                <div class="pinned-mark">{i18n.pinned}</div>
            {/if}
        </div>

        <p class="itsl-plan-card__body">
            {#if plan.description}
                {#if plan.isDescriptionPlainText}
                    {plan.description}
                {:else}
                    {getShortDescription()}
                {/if}
            {/if}
        </p>

        <footer class="itsl-plan-card__footer">
            {#if plan.start && plan.stop && isDateVisible(columnVisibilities, canEdit)}
                <div class="itsl-plan-card__footer-item itsl-plan-card__footer-item--calendar">
                    <div>{dateText}</div>
                </div>
            {/if}
            {#if events}
                <div class="itsl-plan-card__footer-item itsl-plan-card__footer-item--event">
                    <div>{events}</div>
                </div>
            {/if}
            {#if linkedCourses}
                <div class="itsl-plan-card__footer-item itsl-plan-card__footer-item--link">
                    <div>{linkedCourses}</div>
                </div>
            {/if}
            {#if canCurrentUserCompleteElements}
                {#if isPlanLocked}
                    <div
                        class="itsl-plan-card__footer-item itsl-plan-card__footer-item--locked-status"
                    >
                        <div class="itsl-plan-card__status">{i18n.locked}</div>
                    </div>
                {:else if isPlanCompleted}
                    <PlanCompleted />
                {/if}
            {/if}
            {#if showResources() && !isPlanCompleted}
                {#if userCanStartPlan && canCurrentUserCompleteElements && resourcesCompleted !== undefined}
                    <div class="itsl-plan-card__footer-item">
                        <ProgressBar
                            value={resourcesActivitiesCompleted()}
                            max={countMaxProgressBar()}
                            accessibleText={i18n.xOfYCompleted}
                        />
                    </div>
                {:else}
                    <div class="itsl-plan-card__footer-item itsl-plan-card__footer-item--file">
                        <div>{resources}</div>
                    </div>
                {/if}
            {/if}
        </footer>
    </div>

    <div class="itsl-plan-card__spread"></div>
</li>

<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { get } from "svelte/store";
    import { isMobile, isIpad } from "@itslearning/atlas/detectors/UserAgentHelper";
    import { Keys } from "@itslearning/atlas/keyboard/keys";
    import { normalizeKey } from "@itslearning/atlas/keyboard/normalize";
    import { formatLanguageString } from "@itslearning/atlas/strings/format.js"
    import Checkbox from "@itslearning/prometheus/assets/inputs/Checkbox/v1/Checkbox.svelte";
    import Tooltip from "@itslearning/prometheus/assets/modals/Tooltip/v1/Tooltip.svelte";
    import PlanCompleted from "@its-ui/planner-common/components/PlanCompleted/PlanCompleted.svelte";
    import ProgressPlanLink from "@its-ui/planner-common/components/ProgressPlanLink/ProgressPlanLink.svelte";
    import ProgressBar from "../ProgressBar/ProgressBar.svelte";
    import { addBackDestinationParam } from "../../helpers/backLinkHelper";
    import { getDateRangeString } from "../../helpers/dateHelper";
    import { isPlanCompletedHelper } from "../../helpers/isPlanCompletedHelper";
    import { postNewPlannerKpi } from "../../helpers/kpiHelper";
    import { isDateVisible } from "../../helpers/visibilityHelper";
    import config from "../../config";
    import {
        columnTypes,
        columnVisibility,
        resourceTypeToShow,
        planType,
        onePlanAtATimePlanStatus
    } from "../../constants";
    import i18n from "../../i18n";

    const getPlanBackgroundImageStyle = backgroundImageUrl =>
        `background-image: url('${backgroundImageUrl}'); background-color: transparent;`;

    export let plan = undefined;
    export let id = Math.random().toString(36).substr(2, 9);
    export let checked = false;
    export let eventsCount = 0;
    export let linkedCoursesCount = 0;
    export let showSelect = false;
    export let showDrag = false;
    export let locale = undefined;
    export let disabled = false;
    export let hasAccessToPlan = true;
    export let canEdit = false;
    export let isLearner = false;
    export let topicName = "";
    export let showTopicAsLink = true;
    export let target = "_self";
    export let backExtraParams = {};
    export let keyboardReorderSelected;
    export let dragAndDropSelected;
    export let keyboardReorder;
    export let columnVisibilities;
    export let useGenericResourceColumn;
    export let selectedPlanIds = {
        set: () => {}
    };
    export let isCourseOverviewPage = false;

    const resourceCount = plan.elements.filter(
        planElement => planElement.isResource && !planElement.isFolder
    ).length;
    const activityCount = plan.elements.filter(planElement => !planElement.isResource).length;
    const resourcesCompleted = plan.elements.filter(
        planElement => planElement.isResource && !planElement.isFolder && planElement.isCompleted
    ).length;
    const activitiesCompleted = plan.elements.filter(
        planElement => !planElement.isResource && planElement.isCompleted
    ).length;

    const isPlanLocked = config.planType === planType.onePlanAtATime
        && plan.planStatus === onePlanAtATimePlanStatus.locked;

    let cardElement;
    let dragButton;
    let isPlanCompleted = false;
    let tooltipVisible = false;

    onMount(async () => {
        if (window.loadIframelyEmbedJs) {
            window.loadIframelyEmbedJs();
        }
        isPlanCompleted = isPlanCompletedHelper(plan);
    });

    const dispatch = createEventDispatcher();

    $: dateText =
        plan.start && plan.stop ? getDateRangeString(plan.start, plan.stop, locale) : i18n.noDate;
    $: destinationUrl = addBackDestinationParam(plan.url, backExtraParams);
    $: imageStyle = plan.imageUrl
        ? getPlanBackgroundImageStyle(plan.imageUrl)
        : plan.placeholderImageUrl
          ? getPlanBackgroundImageStyle(plan.placeholderImageUrl)
          : "";
    $: hiddenClass = plan.isActive ? "" : "itsl-plan-card--hidden";
    $: hoverSupportClass =
        isMobile() || isIpad() || !userCanStartPlan ? "" : "itsl-plan-card--hover-support";
    $: titleText = plan.title || (i18n ? i18n.noTitle : "");
    $: topicColorStyle = plan.topic ? `background-color: ${plan.topic.color};` : "";

    let events;

    $: {
        if (eventsCount === 1) {
            events = i18n ? i18n.oneEvent : undefined;
        } else if (eventsCount > 1 && i18n && i18n.multipleEvents) {
            events = i18n.multipleEvents.replace("{0}", eventsCount);
        } else {
            events = undefined;
        }
    }

    /**
     * Return an int that will describe what type of resource has to be shown;
     * @returns {int} 0 it shows resources and activities, 1 just resources, 2 just activities
     */
    let resources;
    let areResourcesAndActivitiesCombined;

    $: areResourcesAndActivitiesCombined =
        config.useGenericResourceColumn === true || useGenericResourceColumn === true;

    $: {
        const typeToShow = isResourceOrActivities();

        if (areResourcesAndActivitiesCombined) {
            if (resourceCount + activityCount > 1) {
                resources = formatLanguageString(
                    i18n.multipleResources,
                    resourceCount + activityCount
                );
            } else if (resourceCount + activityCount === 1) {
                resources = i18n ? i18n.oneResource : undefined;
            } else {
                resources = undefined;
            }
        } else {
            if (
                (resourceCount + activityCount === 1 &&
                    typeToShow === resourceTypeToShow.everything) ||
                (resourceCount === 1 && typeToShow === resourceTypeToShow.resources) ||
                (activityCount === 1 && typeToShow === resourceTypeToShow.activities)
            ) {
                resources = i18n ? i18n.oneResource : undefined;
            } else if (resourceCount + activityCount > 1 && i18n && i18n.multipleResources) {
                if (typeToShow === resourceTypeToShow.everything) {
                    resources = formatLanguageString(
                        i18n.multipleResources,
                        resourceCount + activityCount
                    );
                } else if (typeToShow === resourceTypeToShow.resources) {
                    if (resourceCount > 1) {
                        resources = formatLanguageString(i18n.multipleResources, resourceCount);
                    } else {
                        resources = undefined;
                    }
                } else if (typeToShow === resourceTypeToShow.activities) {
                    if (activityCount > 1) {
                        resources = formatLanguageString(i18n.multipleResources, activityCount);
                    } else {
                        resources = undefined;
                    }
                }
            } else {
                resources = undefined;
            }
        }
    }

    let linkedCourses;

    $: {
        if (linkedCoursesCount === 1) {
            linkedCourses = i18n ? i18n.oneLinkedCourse : undefined;
        } else if (linkedCoursesCount > 1 && i18n && i18n.multipleLinkedCourses) {
            linkedCourses = i18n.multipleLinkedCourses.replace("{0}", linkedCoursesCount);
        } else {
            linkedCourses = undefined;
        }
    }

    $: canCurrentUserCompleteElements = !canEdit && isLearner;

    $: userCanStartPlan = !canCurrentUserCompleteElements || !isPlanLocked;

    $: isPlanClickable = hasAccessToPlan && userCanStartPlan;

    function updateCheckedStatus(id, checked) {
        const selectedPlans = get(selectedPlanIds);

        if (!checked) {
            selectedPlans.delete(id);
        } else {
            selectedPlans.add(id);
        }

        selectedPlanIds.set(selectedPlans);
    }

    function mouseDownDragButton(e) {
        dispatch("dragStart", e);
    }

    function clickDragButton(e) {
        dispatch("keyboardDragStart", e);
    }

    function keyUpDragButton(e) {
        if (normalizeKey(e.key) === Keys.TAB) {
            tooltipVisible = true;
        }
    }

    function blurDragButton() {
        tooltipVisible = false;
    }

    /**
     * Return a boolean that tells if 'resources' section has to be shown;
     * @returns {boolean} `true` it shows 'resources' section, `false` it does not show.
     */
    function isResourceVisible() {
        let resourcesVisibility = config.columnVisibilities
            ? config.columnVisibilities[columnTypes.Resources]
            : undefined;
        let activitiesVisibility = config.columnVisibilities
            ? config.columnVisibilities[columnTypes.Activities]
            : undefined;

        if (
            !config.columnVisibilities ||
            resourcesVisibility === undefined ||
            config.canEdit === undefined
        ) {
            // Check if calendar
            if (columnVisibilities) {
                resourcesVisibility = columnVisibilities[columnTypes.Resources];
                activitiesVisibility = columnVisibilities[columnTypes.Activities];

                // Check if teacher logged in
                if (!isLearner) {
                    return !(
                        resourcesVisibility === columnVisibility.Nobody &&
                        activitiesVisibility === columnVisibility.Nobody
                    );
                } else if (isLearner) {
                    // If student
                    return (
                        resourcesVisibility === columnVisibility.All ||
                        activitiesVisibility === columnVisibility.All
                    );
                }
            } else {
                return true;
            }
        }

        // Check if teacher logged in
        if (config.canEdit) {
            return !(
                resourcesVisibility === columnVisibility.Nobody &&
                activitiesVisibility === columnVisibility.Nobody
            );
        } else if (config.canEdit === false) {
            // If student
            return (
                resourcesVisibility === columnVisibility.All ||
                activitiesVisibility === columnVisibility.All
            );
        }
    }

    /**
     * When to show when there is one field for resources and activities.
     * @returns {boolean} `true` it shows resources and activities, `false` does not show.
     */
    function isResourceActivitiesVisible() {
        let resourcesVisibility = config.columnVisibilities
            ? config.columnVisibilities[columnTypes.Elements]
            : undefined;

        if (
            !config.columnVisibilities ||
            resourcesVisibility === undefined ||
            config.canEdit === undefined
        ) {
            // Check if calendar
            if (columnVisibilities) {
                resourcesVisibility = columnVisibilities[columnTypes.Elements];

                // Check if teacher logged in
                if (!isLearner) {
                    return !(resourcesVisibility === columnVisibility.Nobody);
                } else if (isLearner) {
                    // If student
                    return resourcesVisibility === columnVisibility.All;
                }
            } else {
                return true;
            }
        }

        // Check if teacher logged in
        if (config.canEdit) {
            return !(resourcesVisibility === columnVisibility.Nobody);
        } else if (config.canEdit === false) {
            // If student
            return resourcesVisibility === columnVisibility.All;
        }
    }

    /**
     * Return an int that will describe what type of resource has to be shown;
     * @returns {int} 0 it shows resources and activities, 1 just resources, 2 just activities
     */
    function isResourceOrActivities() {
        let resourcesVisibility = config.columnVisibilities
            ? config.columnVisibilities[columnTypes.Resources]
            : undefined;
        let activitiesVisibility = config.columnVisibilities
            ? config.columnVisibilities[columnTypes.Activities]
            : undefined;

        if (!config.columnVisibilities || resourcesVisibility === undefined) {
            // Check if calendar
            if (columnVisibilities) {
                resourcesVisibility = columnVisibilities[columnTypes.Resources];
                activitiesVisibility = columnVisibilities[columnTypes.Activities];

                if (!isLearner) {
                    if (
                        (resourcesVisibility === columnVisibility.Teacher ||
                            resourcesVisibility === columnVisibility.All) &&
                        activitiesVisibility === columnVisibility.Nobody
                    ) {
                        return resourceTypeToShow.resources;
                    }

                    if (
                        (activitiesVisibility === columnVisibility.Teacher ||
                            activitiesVisibility === columnVisibility.All) &&
                        resourcesVisibility === columnVisibility.Nobody
                    ) {
                        return resourceTypeToShow.activities;
                    }
                } else if (isLearner) {
                    // If student
                    if (
                        (activitiesVisibility === columnVisibility.Teacher ||
                            activitiesVisibility === columnVisibility.Nobody) &&
                        resourcesVisibility === columnVisibility.All
                    ) {
                        return resourceTypeToShow.resources;
                    } else if (
                        (resourcesVisibility === columnVisibility.Nobody ||
                            resourcesVisibility === columnVisibility.Teacher) &&
                        activitiesVisibility === columnVisibility.All
                    ) {
                        return resourceTypeToShow.activities;
                    }
                }

                return resourceTypeToShow.everything;
            } else {
                return resourceTypeToShow.everything;
            }
        }

        // Check if Teacher logged in
        if (config.canEdit) {
            if (
                (resourcesVisibility === columnVisibility.Teacher ||
                    resourcesVisibility === columnVisibility.All) &&
                activitiesVisibility === columnVisibility.Nobody
            ) {
                return resourceTypeToShow.resources;
            }

            if (
                (activitiesVisibility === columnVisibility.Teacher ||
                    activitiesVisibility === columnVisibility.All) &&
                resourcesVisibility === columnVisibility.Nobody
            ) {
                return resourceTypeToShow.activities;
            }
        }
        // If student
        else if (!config.canEdit) {
            if (
                (activitiesVisibility === columnVisibility.Teacher ||
                    activitiesVisibility === columnVisibility.Nobody) &&
                resourcesVisibility === columnVisibility.All
            ) {
                return resourceTypeToShow.resources;
            } else if (
                (resourcesVisibility === columnVisibility.Nobody ||
                    resourcesVisibility === columnVisibility.Teacher) &&
                activitiesVisibility === columnVisibility.All
            ) {
                return resourceTypeToShow.activities;
            }
        }

        return resourceTypeToShow.everything;
    }

    function countMaxProgressBar() {
        const typeToShow = isResourceOrActivities();

        if (areResourcesAndActivitiesCombined) {
            return resourceCount + activityCount;
        } else if (typeToShow === resourceTypeToShow.everything) {
            return resourceCount + activityCount;
        } else if (typeToShow === resourceTypeToShow.resources) {
            return resourceCount;
        } else if (typeToShow === resourceTypeToShow.activities) {
            return activityCount;
        }
    }

    function resourcesActivitiesCompleted() {
        const typeToShow = isResourceOrActivities();

        if (areResourcesAndActivitiesCombined) {
            return resourcesCompleted + activitiesCompleted;
        } else if (typeToShow === resourceTypeToShow.everything) {
            return resourcesCompleted + activitiesCompleted;
        } else if (typeToShow === resourceTypeToShow.resources) {
            return resourcesCompleted;
        } else if (typeToShow === resourceTypeToShow.activities) {
            return activitiesCompleted;
        }
    }

    function showResources() {
        return (
            (isResourceVisible() &&
                resources &&
                (config.useGenericResourceColumn === false ||
                    useGenericResourceColumn === false)) ||
            (isResourceActivitiesVisible() && resources && areResourcesAndActivitiesCombined)
        );
    }

    const getShortDescription = () => {
        if (plan.description === null || plan.description === undefined) {
            return "";
        }

        const temporaryElement = document.createElement("div");

        temporaryElement.innerHTML = plan.description;

        return temporaryElement.textContent.trim();
    };

    function measurePlanOpensFromCalendar(event) {
        if (!isPlanClickable) {
            event.stopPropagation();
            event.preventDefault();

            return;
        }

        if (config.redirectFromCalendar) {
            postNewPlannerKpi(config.kpi.kpiMeasurementOpenPlanFromCalendar);
        }
    }
</script>
