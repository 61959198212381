<div class={"itsl-plan-completed"}>
    <ElementProgress {i18n} complete={true} />
    <span aria-hidden="true">{labelText}</span>
</div>

<script>
    import ElementProgress from "@its-ui/planner-common/components/ElementProgress/ElementProgress.svelte";
    import i18n from "../../i18n";

    export let labelText = i18n.completed;
</script>
